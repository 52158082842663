import { defineComponent } from "vue";
import { useUserStore } from "../store/userStore";
import { hapticsImpactLight } from "../utils/haptics";
import { App } from "@capacitor/app";

const hitList = [
    "ion-button",
    "ion-progress-bar",
    "ion-icon",
    "ion-item",
    "ion-back-button",
    "ion-buttons",
    "button"
];

class AudioManager {
    private static instance: AudioManager | null = null;
    public toast: HTMLAudioElement | null = null;
    public menuClick: HTMLAudioElement | null = null;
    public shop: HTMLAudioElement | null = null;
    public woosh: HTMLAudioElement | null = null;
    public streak: HTMLAudioElement | null = null;

    private constructor() {}

    public static getInstance(): AudioManager {
        if (!AudioManager.instance) {
            AudioManager.instance = new AudioManager();
            AudioManager.instance.initAllSounds();
        }
        return AudioManager.instance;
    }

    private initAllSounds() {
        this.toast = this.createAudio("/audio/toast.mp3", 0.3);
        this.menuClick = this.createAudio("/audio/uiclick.wav", 0.5);
        this.shop = this.createAudio("/audio/register.wav", 0.15);
        this.woosh = this.createAudio("/audio/woosh.wav", 0.025);
        this.streak = this.createAudio("/audio/streak.wav", 0.1);
    }

    private createAudio(src: string, volume: number): HTMLAudioElement {
        const audio = new Audio(src);
        audio.volume = volume;
        return audio;
    }

    public playSound(sound: keyof AudioManager) {
        if (
            useUserStore().userPrefs &&
            useUserStore().userPrefs?.sound === false
        )
            return;
        const audioElement = this[sound];
        if (!audioElement) {
            console.error(`Audio element ${sound} not initialized.`);
            return;
        }
        audioElement.play();
    }

    public stopSound(sound: keyof AudioManager) {
        const audioElement = this[sound];
        if (!audioElement) {
            console.error(`Audio element ${sound} not initialized.`);
            return;
        }
        audioElement.pause();
        audioElement.currentTime = 0;
    }

    public getSound(sound: keyof AudioManager): HTMLAudioElement | null {
        return this[sound];
    }

    public stopAllSounds() {
        Object.values(this).forEach((audio) => {
            if (audio instanceof HTMLAudioElement) {
                audio.pause();
                audio.currentTime = 0;
            }
        });
    }
}

let initialized = false;
let clickListenerAdded = false;

export default defineComponent({
    data() {
        return {
            boundListener: null as ((event: MouseEvent) => void) | null,
            centerSoundSource: null as HTMLAudioElement | null
        };
    },
    methods: {
        playClickSound(): void {
            const audioManager = AudioManager.getInstance();
            hapticsImpactLight();
            audioManager.playSound("menuClick");
        },
        playToastSound(): void {
            const audioManager = AudioManager.getInstance();
            audioManager.playSound("toast");
        },
        playShopSound(): void {
            const audioManager = AudioManager.getInstance();
            audioManager.playSound("shop");
        },
        playStreakSound(): void {
            const audioManager = AudioManager.getInstance();
            audioManager.playSound("streak");
        },
        playCenterSound(totalDuration: number): void {
            const audioManager = AudioManager.getInstance();
            const woosh = audioManager.getSound("woosh");

            if (
                !woosh ||
                (useUserStore().userPrefs &&
                    useUserStore().userPrefs?.sound === false)
            ) {
                return;
            }
            this.stopCenterSound();

            this.centerSoundSource = woosh;
            const calculatedPlaybackRate =
                woosh.duration / (totalDuration / 1000);
            woosh.playbackRate = isFinite(calculatedPlaybackRate)
                ? calculatedPlaybackRate
                : 1;
            const playPromise = woosh.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        setTimeout(() => {
                            this.stopCenterSound();
                        }, totalDuration);
                    })
                    .catch((error) => {
                        console.error("Failed to play sound:", error);
                    });
            }
        },
        stopCenterSound(): void {
            if (this.centerSoundSource) {
                this.centerSoundSource.pause();
                this.centerSoundSource.currentTime = 0;
                this.centerSoundSource = null;
            }
        },
        addClickSoundListener(): void {
            if (clickListenerAdded) return;

            const boundListener = (event: MouseEvent) => {
                if (
                    event.target instanceof HTMLElement &&
                    (event.target.matches(hitList.join(", ")) ||
                        event.target.closest(hitList.join(", ")))
                ) {
                    this.playClickSound();
                }
            };
            document.addEventListener("click", boundListener);
            this.boundListener = boundListener;
            clickListenerAdded = true;
        },
        stopAllSounds(): void {
            const audioManager = AudioManager.getInstance();
            audioManager.stopAllSounds();
            this.stopCenterSound();
        }
    },
    mounted(): void {
        if (initialized) return;
        AudioManager.getInstance();
        this.addClickSoundListener();
        App.addListener("appStateChange", ({ isActive }) => {
            if (!isActive) {
                this.stopAllSounds();
            }
        });
        initialized = true;
    },
    beforeUnmount(): void {
        if (this.boundListener) {
            document.removeEventListener("click", this.boundListener);
        }
        App.removeAllListeners();
    }
});
