import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { useUserStore } from "../store/userStore";
import { Capacitor } from "@capacitor/core";

const isNative = Capacitor.isNativePlatform();

const getUserHapticsEnabled = () => useUserStore().userPrefs?.haptics ?? true;
const hapticCheck = () => isNative && getUserHapticsEnabled;

export const hapticsImpactHeavy = () =>
    hapticCheck() && Haptics.impact({ style: ImpactStyle.Heavy });

export const hapticsImpactMedium = () =>
    hapticCheck() && Haptics.impact({ style: ImpactStyle.Medium });

export const hapticsImpactLight = () =>
    hapticCheck() && Haptics.impact({ style: ImpactStyle.Light });

export const hapticsVibrate = () => hapticCheck() && Haptics.vibrate();

export const hapticsSelectionStart = () =>
    hapticCheck() && Haptics.selectionStart();

export const hapticsSelectionChanged = () =>
    hapticCheck() && Haptics.selectionChanged();

export const hapticsSelectionEnd = () =>
    hapticCheck() && Haptics.selectionEnd();
